module.exports=[
  {
    "name": "Syndication Service",
    "date": "(2016)",
    "description": "Created a microservice that syndicates CNET articles to 3rd parties, such as Apple News.",
    "urls": [
    ],
    "skills": [
      "Docker",
      "PHP",
      "Jenkins",
      "Microservice"
    ],
    "assets": [
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/syndication-ipad.small.png",
        "src": "static/images/sites/syndication-ipad.large.png",
        "width": 1024,
        "height": 768,
        "title": "Syndication Service (2016) - Apple News"
      },
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/syndication-amp.small.png",
        "src": "static/images/sites/syndication-amp.large.png",
        "width": 412,
        "height": 732,
        "title": "Syndication Service (2016) - Google AMP"
      }
    ]
  },
  {
    "name": "Guzzlefish",
    "date": "(2015-2016)",
    "description": "Guzzlefish was a quirky news site that I developed and launched at CBS Interactive.",
    "urls": [
      {
        "url": "http://www.guzzlefish.com/",
        "text": "Visit Guzzlefish"
      },
      {
        "url": "http://facebook.com/guzzlefish",
        "text": "Visit Guzzlefish on Facebook"
      }
    ],
    "skills": [
      "PHP",
      "MySQL",
      "HTML5",
      "JavaScript",
      "Sass",
      "Solr",
      "jQuery",
      "Memcached",
      "Varnish",
      "Responsive"
    ],
    "assets": [
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/guzzlefish.desktop.small.png",
        "src": "static/images/sites/guzzlefish.desktop.large.jpg",
        "width": 1280,
        "height": 798,
        "title": "Guzzlefish (2015-2016) - Desktop"
      },
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/guzzlefish.mobile.small.png",
        "src": "static/images/sites/guzzlefish.mobile.large.png",
        "width": 600,
        "height": 798,
        "title": "Guzzlefish (2015-2016) - Mobile Web"
      }
    ]
  },
  {
    "name": "CNET Forums",
    "date": "(2014)",
    "description": "Migrated the CNET Forums onto a new CMS.",
    "urls": [
      {
        "url": "http://www.cnet.com/forums/",
        "text": "Visit CNET Forums"
      }
    ],
    "skills": [
      "PHP",
      "MySQL",
      "Solr",
      "JavaScript"
    ],
    "assets": [
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/cnet-forums.desktop.small.png",
        "src": "static/images/sites/cnet-forums.desktop.png",
        "width": 1024,
        "height": 818,
        "title": "CNET Forums (2014) - Desktop"
      },
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/cnet-forums.mobile.small.png",
        "src": "static/images/sites/cnet-forums.mobile.png",
        "width": 412,
        "height": 732,
        "title": "CNET Forums (2014) - Mobile Web"
      }
    ]
  },
  {
    "name": "CBS Connect",
    "date": "(2012)",
    "description": "Built the RESTful API for CBS Connect, a social webapp that connects prime time TV fans to CBS shows and stars.",
    "urls": [
      {
        "url": "http://www.cbs.com/connect/",
        "text": "Visit CBS Connect"
      }
    ],
    "skills": [
      "Java",
      "Spring",
      "Hibernate",
      "MySQL",
      "Solr",
      "Redis",
      "API"
    ],
    "assets": [
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/cbs.desktop.small.png",
        "src": "static/images/sites/cbs.desktop.large.jpg",
        "width": 1028,
        "height": 675,
        "title": "CBS Connect (2012)"
      }
    ]
  },
  {
    "name": "TV.com Mobile Web",
    "date": "(2012)",
    "description": "Built the frontend for TV.com's first mobile web experience.",
    "urls": [
      {
        "url": "http://www.tv.com/",
        "text": "Visit TV.com"
      }
    ],
    "skills": [
      "Python",
      "Django",
      "jQuery",
      "HTML5",
      "JavaScript",
      "CSS3",
      "Mobile Web"
    ],
    "assets": [
      {
        "type": "image",
        "thumbnail_src": "static/images/sites/tv.desktop.small.jpg",
        "src": "static/images/sites/tv-mobile-large.jpg",
        "width": 640,
        "height": 919,
        "title": "TV.com (2012) - Mobile Web"
      }
    ]
  }
]